@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "primereact/resources/themes/lara-light-blue/theme.css";
@import 'primereact/resources/primereact.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.link {
  @apply p-3 flex gap-6 text-white items-center md:cursor-pointer cursor-default duration-300;
}

.active {
  @apply bg-blue-300/60 text-white border-r-4 border-white;
}
.sidebar-btn{
  transform: perspective(12vw) rotateY(45deg);
}
.p-toast .p-toast-message{
  margin: 1rem;
}
.p-toast-message-content{
  align-items: center;
}
.p-datatable-wrapper{
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.scrollable {
-webkit-overflow-scrolling: touch;
scrollbar-width: none; 
-ms-overflow-style: none; 
}

.scrollable::-webkit-scrollbar {
display: none;
}